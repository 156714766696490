import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getPage } from "../api";

export function useGetPage(proxy_link: string) {
    const query = useQuery(['page', proxy_link], async () => {
        try {
            const session = localStorage.getItem('session');
            if (session === null) {
                throw new Error('Please login to continue.');
            }
            return await getPage(proxy_link, session);
        } catch (error) {
            localStorage.removeItem('session');
            throw error;
        }
    }, {
        onError(error: any) {
            if (error.response && error.response.status === 302) {
                toast.error('Please login to continue.', { autoClose: 5000 })
            } else if (error?.response?.data) toast.error(error?.response?.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error.message, { autoClose: 5000 })
            else {
                toast.error('Errors while serving the page. Please check and try again.', { autoClose: 5000 })
            }
        },
        enabled: !!proxy_link,
        retry: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 20,
        cacheTime: 1000 * 60 * 20,
    });
    return query;
};
