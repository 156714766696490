import Loader from "./Loader";

export default function Button(props: any): JSX.Element {
  const { children, className, loading, disabled, ...rest } = props;
  return (
    <button
      className={`btn ${className}`}
      {...rest}
      disabled={disabled || loading}
    >
      {children} {loading && <Loader darken />}
    </button>
  );
}
