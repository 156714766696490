import styles from "../styles/Common.module.scss";

export default function Loader(props: any): JSX.Element {
  return (
    <div className={`${styles.loader_container} ${props.className || ""}`}>
      <i
        className={`${styles.loader} ${
          props.darken ? styles.darken_loader : ""
        }`}
      ></i>
    </div>
  );
}
