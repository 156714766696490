import { Web3Modal } from "@web3modal/react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ethereumClient } from "./config/web3config";
import { Auth, Complaints, Error, ProxySite } from "./pages";
import "./styles/fonts.scss";
import "./styles/globals.scss";

function App() {
  return (
    <>
      <main>
        <Routes>
          <Route path="/:id" element={<ProxySite />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/complaint/:proxy_link" element={<Complaints />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </main>

      <ToastContainer />

      <Web3Modal
        projectId={process.env.REACT_APP_WEB3_PROJECT_ID as string}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "#2563eb",
        }}
      />
    </>
  );
}

export default App;
