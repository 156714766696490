import { useWeb3Modal } from "@web3modal/react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSignMessage } from "wagmi";
import Gatr from "../assets/images/0xgatr.png";
import { Button } from "../components";
import { WalletIcon } from "../components/Icons";
import { useWallet } from "../context/web3.context";
import {
  useGetRegisterMessage,
  useVerifyRegisterMessage,
} from "../hooks/auth.hooks";
import { useGetGateByProxy } from "../hooks/gates.hooks";
import styles from "../styles/Auth.module.scss";

export default function Auth(): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isConnected, address } = useWallet();
  const { open } = useWeb3Modal();
  const { error, signMessageAsync } = useSignMessage();
  const { verifyMessage } = useVerifyRegisterMessage();
  const [searchParams] = useSearchParams();
  const { getMessage } = useGetRegisterMessage(
    address || "",
    searchParams.get("redirect") || ""
  );
  const {
    data: gateData,
    isLoading: isGateLoading,
    status,
  } = useGetGateByProxy(searchParams.get("redirect") || "");

  const createConditionText = useMemo(() => {
    if (gateData?.data) {
      const {
        conditions: { amount, attribute },
        symbol,
        token,
      } = gateData?.data;
      if (attribute) {
        const [key, value] = attribute.split(":");
        return `To access this content you must hold ${amount} or more ${symbol} NFTs in your Ethereum wallet, that have the ${value.trim()} ${key.trim()} attribute.`;
      } else if (amount && token === "ERC721") {
        return `To access this content you must hold ${amount} or more ${symbol} NFTs in your Ethereum wallet.`;
      } else if (amount && token === "ERC20") {
        return `To access this content you must hold ${amount} or more ${symbol} tokens in your Ethereum wallet.`;
      } else {
        return "";
      }
    }
  }, [gateData?.data]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!searchParams.get("redirect")) {
        throw new Error("No redirect link provided.");
      }
      if (!address || !isConnected) {
        await open();
      } else {
        const message = await getMessage();
        const signature = await signMessageAsync({ message: message?.data });
        await verifyMessage({
          signature: signature,
          address: address,
          proxy_link: searchParams.get("redirect"),
        });
        navigate(`/${searchParams.get("redirect")}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isConnected && error) {
      window.location.reload();
    }
  }, [error, isConnected]);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.auth_image}>
          <img src={Gatr} alt="0xGATR" />
          <div className={styles.caption}>
            <p className={styles.link}>
              Token-gating provided by{" "}
              <Link to="https://0xGatr.io" target="_blank">
                0xGatr.io
              </Link>
              .
            </p>
            <i>“Do you have what it takes?”</i>
            <cite>
              Copyright © and Trademark ™, 2024, 0xGatr.io. All rights reserved.
            </cite>
          </div>
        </div>
        <div className={styles.auth_box}>
          <h2 className={styles.auth_header}>
            YOU ARE ATTEMPTING TO ACCESS TOKEN-GATED CONTENT!
          </h2>
          <div className={styles.body}>
            {isGateLoading ? (
              <p>Loading gate info...</p>
            ) : gateData?.data.status === "live" ? (
              <>
                <strong>{createConditionText}</strong>
                <Button
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={status !== "success"}
                  className={styles.login_button}
                >
                  <WalletIcon />{" "}
                  {loading
                    ? "Connecting..."
                    : isConnected
                    ? "VERIFY WALLET"
                    : "CONNECT WALLET"}
                </Button>
              </>
            ) : (
              <strong>
                <p>This link has been {gateData?.data.status}.</p>
                <p>Please check back later or contact the administrator.</p>
              </strong>
            )}
            {error && <div className="error">{error.message}</div>}
          </div>
          <div className={styles.caption}>
            <p className={styles.link}>
              Token-gating provided by{" "}
              <Link to="https://0xGatr.io" target="_blank">
                0xGatr.io
              </Link>
              .
            </p>
            <i>“Do you have what it takes?”</i>
            <cite>
              Copyright © and Trademark ™, 2024, 0xGatr.io. All rights reserved.
            </cite>
          </div>
        </div>
      </div>
    </section>
  );
}
