export default function Linkify({ children }: { children: string }) {
  const isUrl = (word: string) => {
    const urlPattern = new RegExp(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
    );
    return word.match(urlPattern);
  };

  const addMarkup = (word: string) => {
    const _isUrl = isUrl(word);
    if (_isUrl && !word.startsWith("http")) {
      word = `http://${word}`;
    }

    return _isUrl
      ? `<a href="${word}" target="_blank" rel="noreferrer">${word}</a>`
      : word;
  };

  const words = children.split(" ");
  const formatedWords = words.map((w) => addMarkup(w));
  const html = formatedWords.join(" ");
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}
