import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getGateByProxy } from "../api";
import { GateResponse } from "../types";

export function useGetGateByProxy(proxy_link: string) {
    const query = useQuery<AxiosResponse<GateResponse>, Error>(['gate', proxy_link], () => getGateByProxy(proxy_link), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching gate. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        enabled: !!proxy_link,
        retry: 3,
    })

    return query;
}
