import { FormEvent, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Footer, Navbar } from "../components";
import { usePostComplaint } from "../hooks/complaints.hooks";
import styles from "../styles/Complaints.module.scss";

export default function Complaints(): JSX.Element {
  const query = usePostComplaint();
  const navigate = useNavigate();
  const { proxy_link } = useParams();
  const [searchParams] = useSearchParams();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!proxy_link) return;
      const { email, subject, message } = event.currentTarget.elements as any;
      await query.mutateAsync({
        email: email.value,
        subject: subject.value,
        message: message.value,
        proxy_link,
      });
      toast.success("Report sent successfully!");
      navigate(`/${proxy_link}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (searchParams.get("session")) {
      searchParams.delete("session");
    }
  }, [searchParams]);

  return (
    <div className={styles.section}>
      {/* <div className="bg_gradient"></div> */}
      <Navbar />
      <div className={styles.container}>
        <h1 className={styles.head}>Content Report</h1>
        <p className={styles.disclaimer}>
          We take content reports very seriously. Your report will be thoroughly
          investigated and appropriate actions will be taken to deal with
          violations of our Terms of Use. Thank you for reporting!
        </p>
        <form className={styles.wrapper} onSubmit={handleSubmit}>
          <div className={styles.input_group}>
            <div>
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" id="email" />
            </div>
          </div>
          <div className={styles.input_group}>
            <div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" name="subject" id="subject" />
            </div>
            <p className={styles.limit}>(Max: 150 characters)</p>
          </div>
          <div className={styles.input_group}>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea
                name="message"
                id="message"
                cols={30}
                rows={10}
                maxLength={3000}
              ></textarea>
            </div>
            <p className={styles.limit}>(Max: 3000 characters)</p>
          </div>
          <button
            className="gradient_btn"
            type="submit"
            disabled={query.isLoading}
          >
            {query.isLoading ? "Sending..." : "REPORT"}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
