const isWrapping = (previous: Element, current: Element) => {
    return previous.getBoundingClientRect().top !== current.getBoundingClientRect().top;
}

function detectWrapping(current: HTMLDivElement): boolean {
    if (!current.children[0]) return false;
    if (isWrapping(current.children[0], current.children[current.children.length - 1]))
        return true;
    return false;
}

function handleDriveLinks(link: string): string {
    if (link.includes("drive.google.com")) {
        const driveUrl = new URL(link);
        const isFile = driveUrl.pathname.split("/")[1] === "file";
        if (isFile) {
            const driveId = driveUrl.pathname.split("/")[3];
            return `https://drive.google.com/uc?id=${driveId}`;
        }
    }
    return link;
}

export { detectWrapping, handleDriveLinks };
