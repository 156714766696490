import axios from 'axios';
import { ComplaintRequest, GateResponse, SiteResponse } from '../types';

const backend = axios.create({
    baseURL: process.env.REACT_APP_PROXY_URL || 'http://localhost:4000',
});

const gateService = axios.create({
    baseURL: process.env.REACT_APP_GATE_URL || 'http://localhost:5000/v1',
    validateStatus: function (status) {
        return status >= 200 && status <= 302
    }
});

export const getRegisterMessage = async (address: string, proxy_link: string) => backend.get(`/auth/get-hash/${proxy_link}/${address}`);
export const verifyRegisterMessage = async (data: any) => backend.post('/auth', data);
export const getPage = async (proxy_link: string, session: string) => backend.get<SiteResponse>(`/proxy/${proxy_link}?session=${session}`);

export const getGateByProxy = async (proxy_link: string) => gateService.get<GateResponse>(`/gates/conditions/${proxy_link}`);
export const postComplaint = async (data: ComplaintRequest) => gateService.post(`/complaints`, data);
