import React from 'react'
import styles from '../styles/Footer.module.scss'
import { FacebookIcon, TelegramIcon, YoutubeIcon, TwitterIcon, InstagramIcon, DiscordIcon } from './Icons'


const Footer = () => {
  return (
    <div className={styles.section}>
      <div>Copyright © and Trademark TM, 2024, 0xGatr.io. All rights reserved.</div>
      <ul className={`${styles.footer_links} ${styles.original}`}>
        <li><a href={`${process.env.REACT_APP_HOME_URL}/#hero`}>HOME</a></li>
        <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_HOME_URL}/privacy-policy`}>PRIVACY POLICY</a></li>
        <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_HOME_URL}/terms-of-use`}>TERMS OF USE</a></li>
        <li><a href="https://n4cz4diqeujmu4opnvwcrqe32lanaw2p72rgfqirkjjfgnbidl2q.arweave.net/bwWeDRAlEspxz21sKMCb0sDQW0_-omLBEVJSUzQoGvU" target='_blank' rel='noopener noreferrer'>SWAMP PAPER</a></li>
        <li><a href="https://0xgatr.com/contact-us" target='_blank' rel='noopener noreferrer'>CONTACT US</a></li>
        </ul>
        <ul className={styles.icons}>
        <a target="_blank" rel="noreferrer" href='https://twitter.com/0xgatr'><TwitterIcon /></a>
        <a target="_blank" rel="noreferrer" href='https://facebook.com/0xgatr'><FacebookIcon /></a>
        <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCuKIJ2HvwvMsALJ3zWxTKlQ'><YoutubeIcon /></a>
        <a target="_blank" rel="noreferrer" href='https://t.me/oxgatr'><TelegramIcon /></a>
        <a target="_blank" rel="noreferrer" href='https://www.instagram.com/0xgatr'><InstagramIcon /></a>
        <a target="_blank" rel="noreferrer" href='https://discord.gg/8BeuaMECXZ'><DiscordIcon /></a>
      </ul>
    </div>
  )
}

export default Footer
