import "@google/model-viewer/dist/model-viewer";
import { Suspense, useEffect, useRef, useState } from "react";
import styles from "../styles/FileViewer.module.scss";
import { CrossIcon, DownloadIcon, FileIcon, FullScreenIcon } from "./Icons";

export default function FileViewer({
  type,
  file,
  download = true,
  className,
}: {
  type:
    | "HTML"
    | "DOCX"
    | "JPG"
    | "PNG"
    | "MP3"
    | "MP4"
    | "PDF"
    | "APK"
    | "GLB"
    | "ZIP";
  file: string[];
  download?: boolean;
  className?: string;
}): JSX.Element {
  const fileRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    const file = fileRef.current;
    if (file) {
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setIsFullScreen(false);
          file.classList.remove(styles.fullscreen_file);
        }
      } else {
        if (file.requestFullscreen) {
          file.requestFullscreen();
          setIsFullScreen(true);
          file.classList.add(styles.fullscreen_file);
        }
      }
    }
  };

  const handleHTMLDownload = (html: string) => {
    const anchor = anchorRef.current;
    if (anchor) {
      const blob = new Blob([html], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = "index.html";
    }
  };

  useEffect(() => {
    const _fileRef = fileRef;
    const _iframeRef = iframeRef;

    const file = _fileRef.current;
    if (file) {
      // const containerWidth = file.offsetWidth;
      if (type === "HTML") {
        const iframe = _iframeRef.current;
        iframe?.addEventListener("load", () => {
          if (iframe.contentWindow) {
            iframe.style.height =
              iframe.contentWindow.document.body?.scrollHeight + 35 + "px";
          }
          if (
            iframe.contentDocument &&
            iframe.contentDocument.body.scrollWidth
          ) {
            const iframeWidth = iframe.contentDocument.body.scrollWidth;
            // const scaleX = containerWidth / iframeWidth;
            // iframe.style.transform = `scale(${scaleX})`;
            iframe.style.width = iframeWidth + "px";
          } else if (
            iframe.contentDocument &&
            iframe.contentDocument.body.offsetWidth
          ) {
            const iframeWidth = iframe.contentDocument.body.scrollWidth;
            // const scaleX = containerWidth / iframeWidth;
            // iframe.style.transform = `scale(${scaleX})`;
            iframe.style.width = iframeWidth + "px";
          }
        });
      }
    }

    return () => {
      const file = _fileRef.current;
      if (file) {
        // const containerWidth = file.offsetWidth;
        if (type === "HTML") {
          const iframe = _iframeRef.current;
          iframe?.removeEventListener("load", () => {
            if (iframe.contentWindow) {
              iframe.style.height =
                iframe.contentWindow.document.body?.scrollHeight + 35 + "px";
            }
            if (
              iframe.contentDocument &&
              iframe.contentDocument.body.scrollWidth
            ) {
              const iframeWidth = iframe.contentDocument.body.scrollWidth;
              // const scaleX = containerWidth / iframeWidth;
              // iframe.style.transform = `scale(${scaleX})`;
              iframe.style.width = iframeWidth + "px";
            } else if (
              iframe.contentDocument &&
              iframe.contentDocument.body.offsetWidth
            ) {
              const iframeWidth = iframe.contentDocument.body.scrollWidth;
              // const scaleX = containerWidth / iframeWidth;
              // iframe.style.transform = `scale(${scaleX})`;
              iframe.style.width = iframeWidth + "px";
            }
          });
        }
      }
    };
  }, [file, type]);

  useEffect(() => {
    const file = fileRef.current;

    function onFullscreenChange() {
      setIsFullScreen(Boolean(document.fullscreenElement));
      if (file) {
        if (document.fullscreenElement) {
          file.classList.add(styles.fullscreen_file);
        } else {
          file.classList.remove(styles.fullscreen_file);
        }
      }
    }

    if (file) {
      file.addEventListener("fullscreenchange", onFullscreenChange);
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      if (file) {
        file.removeEventListener("fullscreenchange", onFullscreenChange);
      }

      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  const viewer = () => {
    switch (type) {
      case "HTML":
        return (
          <iframe ref={iframeRef} srcDoc={file[0]} title="HTML Attachment" />
        );
      case "DOCX":
        return (
          <a
            href={file[0]}
            download={file}
            className={styles.anchor}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <FileIcon />
              <span>Download DOC</span>
            </div>
            <DownloadIcon />
          </a>
        );
      case "JPG":
      case "PNG":
        return <img src={file[0]} alt="Attachment" />;
      case "MP3":
        return (
          <audio controls controlsList="nodownload">
            <source src={file[0]} type="audio/mpeg" />
            {file[1] ? <source src={file[1]} type="audio/mpeg" /> : <></>}
          </audio>
        );
      case "MP4":
        return (
          <video controls controlsList="nodownload">
            <source src={file[0]} type="video/mp4" />
            {file[1] ? <source src={file[1]} type="video/mp4" /> : <></>}
          </video>
        );
      case "PDF":
        return <embed src={file[1] + "#toolbar=0"} type="application/pdf" />;
      case "APK":
      case "ZIP":
        return (
          <a
            href={file[0]}
            download={file}
            className={styles.anchor}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <FileIcon />
              <span>Download {type === "APK" ? "APK File" : "ZIP File"}</span>
            </div>
            <DownloadIcon />
          </a>
        );
      case "GLB":
        return (
          <Suspense fallback={<p>Loading...</p>}>
            <model-viewer
              src={file[0] || file[1]}
              id="model"
              align-model="origin"
              auto-rotate
              auto-play
              camera-controls
              touch-action="pan-y"
              shadow-intensity="1"
              alt="3D Model Viewer"
            ></model-viewer>
          </Suspense>
        );
      default:
        return <p>Unsupported file format: {type}</p>;
    }
  };

  return (
    <div
      className={`${styles.file} ${type === "GLB" ? styles.bg_black : ""} ${
        className ? className : ""
      }`}
      ref={fileRef}
    >
      <div className={styles.controls}>
        {type === "APK" || type === "ZIP" || type === "DOCX" ? (
          <></>
        ) : (
          <i
            className={styles.icon}
            onClick={handleFullScreen}
            title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
          >
            {isFullScreen ? <CrossIcon /> : <FullScreenIcon />}
          </i>
        )}
        {download && type !== "APK" && type !== "ZIP" && type !== "DOCX" ? (
          <a
            title="Download"
            href={file[0] || file[1]}
            download={file}
            onClick={
              type === "HTML"
                ? () => handleHTMLDownload(file[0] || file[1])
                : () => {}
            }
            className={styles.icon}
            ref={anchorRef}
          >
            <DownloadIcon />
          </a>
        ) : (
          <></>
        )}
      </div>
      {viewer()}
    </div>
  );
}
