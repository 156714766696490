import { createContext, useContext } from "react";
import { useAccount, useNetwork } from "wagmi";
import { Props } from "../types";

const walletContext = createContext({
  isConnected: false as boolean,
  address: "" as string | undefined,
  chainId: 0 as number | undefined,
});

const WalletProvider = ({ children }: Props): JSX.Element => {
  const { Provider } = walletContext;
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();

  return (
    <Provider
      value={{
        isConnected,
        address,
        chainId: chain?.id,
      }}
    >
      {children}
    </Provider>
  );
};

export default WalletProvider;

export const useWallet = () => useContext(walletContext);
