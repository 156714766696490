"use client";

import React, { useEffect } from 'react'
import styles from "../styles/Navbar.module.scss"
import Logo from "../assets/images/navbarLogo.png"
import { CrossIcon, MenuIcon } from './Icons';


const Navbar = () => {

  useEffect(() => {
    const mobile_nav = document.getElementById("mobile_nav");
    const open_button = document.getElementById("open_button");
    const close_button = document.getElementById("close_button");

    open_button?.addEventListener("click", () => {
      openNav(mobile_nav, open_button, close_button);
    });

    close_button?.addEventListener("click", () => {
      closeNav(mobile_nav, open_button, close_button);
    });

    return () => {
      open_button?.removeEventListener("click", () => {
        openNav(mobile_nav, open_button, close_button);
      });

      close_button?.removeEventListener("click", () => {
        closeNav(mobile_nav, open_button, close_button);
      });
    };
  }, []);

  const closeNavOnScroll = () => {
    const mobile_nav = document.getElementById("mobile_nav");
    const open_button = document.getElementById("open_button");
    const close_button = document.getElementById("close_button");

    closeNav(mobile_nav, open_button, close_button);
  }

  const openNav = (mobile_nav: any, open_button: any, close_button: any) => {
    mobile_nav.classList.remove("hide");
    open_button.classList.add("hide");
    close_button.classList.remove("hide");
  };

  const closeNav = (mobile_nav: any, open_button: any, close_button: any) => {
    mobile_nav.classList.add("hide");
    open_button.classList.remove("hide");
    close_button.classList.add("hide");
  };

  return (
    <nav className={`${styles.header}`}>
      <div className={styles.container}>
      <a href={`${process.env.REACT_APP_HOME_URL}/#hero`}>
        <img src={Logo} alt='logo' className={styles.navbar_logo}></img>
      </a>

      <ul className={`${styles.list}`}>
      <li><a href={`${process.env.REACT_APP_HOME_URL}/#about`}>ABOUT</a></li>
        <li><a href={`${process.env.REACT_APP_HOME_URL}/featured`}>FEATURED GATES</a></li>
        <li><a href="https://n4cz4diqeujmu4opnvwcrqe32lanaw2p72rgfqirkjjfgnbidl2q.arweave.net/bwWeDRAlEspxz21sKMCb0sDQW0_-omLBEVJSUzQoGvU" target='_blank' rel='noopener noreferrer'>SWAMP PAPER</a></li>
        <li><a href="https://medium.com/@Support_11472" target='_blank' rel='noopener noreferrer'>NEWS</a></li>
      </ul>
      <button className={styles.connect} onClick={() => window.open((process.env.REACT_APP_DAPP_URL as string) + "/auth", "_self")}>
          DAPP
      </button>
      {/* <BarsIcon className={styles.menu} /> */}
      <button id='open_button' className={styles.open}><MenuIcon /></button>
      <div id='mobile_nav' className={`${styles.mobile_navbar} hide`}>
      <button id='close_button' className={`${styles.close} hide`}><CrossIcon /></button>
      <button className={styles.mobile_connect} onClick={() => window.open((process.env.NEXT_PUBLIC_DAPP_URL as string) + "/auth", "_self")}>DAPP</button>
      <ul className={`${styles.mobile_list}`}>
        <li><a href={`${process.env.REACT_APP_HOME_URL}/#about`} onClick={closeNavOnScroll}>ABOUT</a></li>
        <li><a href={`${process.env.REACT_APP_HOME_URL}/featured`} onClick={closeNavOnScroll}>FEATURED GATES</a></li>
        <li><a href="https://n4cz4diqeujmu4opnvwcrqe32lanaw2p72rgfqirkjjfgnbidl2q.arweave.net/bwWeDRAlEspxz21sKMCb0sDQW0_-omLBEVJSUzQoGvU" target='_blank' rel='noopener noreferrer' onClick={closeNavOnScroll}>SWAMP PAPER</a></li>
        <li><a href="https://medium.com/@Support_11472" target='_blank' rel='noopener noreferrer' onClick={closeNavOnScroll}>NEWS</a></li>
      </ul>
      </div>
      </div>
    </nav>
  )
}

export default Navbar
