import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { postComplaint } from "../api";
import { AxiosResponse } from "axios";
import { ComplaintRequest } from "@/types";

export function usePostComplaint() {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<any>,ComplaintRequest, any>(async (data) => {
        try {
            return await postComplaint(data);
        } catch (error) {
            throw error;
        }
    }, {
        onSuccess() {
            queryClient.invalidateQueries('complaints');
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating gate. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    },);

    return query;
}
